.payment-tokens-list {
  .rw-widget {
    //   background-color: #131721 !important;
  }

  .rw-widget-input {
    // background: #131721 !important;
    background: transparent !important;
    border: none !important;
    border-radius: 10px !important;
    padding: .3rem .4rem;
    
    &:hover,
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
  }
  
  .rw-dropdown-list-input {
      padding: 0px 0px 0px 5px !important;
      align-items: center !important;
  }
  
  .rw-dropdownlist-search {
      color: white !important;
      padding-left: 27px;
  }
  
  .rw-popup-container {
      width: 230px;
      padding: 3px 0px 0px 0.7em !important;
      
      @media screen and (max-width: 600px){
          width: 200px;
      }
  }
  
  .rw-slide-transition {
      background: #131721 !important;
      border-radius: 15.6px 15.6px !important;
      border: none !important;
      margin-bottom: 0px !important;
  }
  
  .rw-list {
      overflow-x: hidden !important;
  }
  
  .rw-list-option {
      padding: 5px 5px !important;
      &:hover {
          background: #06071A !important;
          border: none !important;
      }
  }
  
  .rw-state-selected {
      background: #06071A !important;
      border: none !important;
  }
}
