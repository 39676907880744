.collection-info {
  padding: 20px 16px;

  margin-bottom: 30px;

  // background: #232323;
  // border: 1px solid #3C3C3C;
  // border-radius: 12px;

  background: black;
  border-radius: 10px;

  h5 {
    color: #10ffc6;

    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  p {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #c7c7c7;

    span {
      color: #ffffff;
    }
  }

  hr {
    margin: 14px 0;
    border-bottom: 1px solid #000000;
  }

  img {
    margin: 18px auto 0px;
    width: 100%;
  }

  a {
    img {
      border: 1px solid #a8b0cf;
      border-radius: 10px;
      transition: 0.3s all ease-in-out;

      &:hover {
        border: 1px solid $green;
        transform: rotate(15deg);
      }
    }
  }
}
