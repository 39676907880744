.buy-token {
    .swap-token {
        margin-bottom: 30px;
        background: #232323;
        border: 1px solid #3C3C3C;
        border-radius: 12px;

        padding: 20px 16px;

        h3 {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            color: #FFFFFF;
        }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #C7C7C7;
        }

        .input-group {
            height: 40px;
        }

        input {
            flex-grow: 1;
            background: #000;
            border: none;
            border-radius: 8px;
            color: #fff;
            padding-left: 10px;
            height: 40px;
            border: 1px solid #989898;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
        }

        button {
            background: #10FFC6;
            border: 1px solid #3C3C3C;
            border-radius: 8px;
            border: 1px solid #989898;
        }

        span {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: #FFFFFF;
            margin-top: 16px;
            margin-bottom: 8px;
        }


        .swap-footer {
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #C7C7C7;
                margin: 0;

                span {
                    color: #00FFA3;
                }
            }

            a {
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                text-decoration: underline !important;
                color: #00B2FF ;
            }
        }

        .swap-button {
            height: 40px;
            width: 200px;
            background: #10FFC6;
            border: 1px solid #3C3C3C;
            border-radius: 8px;

            font-weight: 500;
            font-size: 18px;
            line-height: 24px;
        }
    }
}