.maxBtn {
  background: transparent;
  margin-right: 10px;
  border: none;
  font-size: .8rem;

  // width: 60px !important;
  // height: 24px;

  // border: 1px solid #FF9D19 !important;
  // border-radius: 8px;
  // padding: 0 10px;
  color: #10FFC6;

  &:hover {
      background: transparent;
      border: none;
  }

  &:focus {
      background: transparent;
      color: #10FFC6;
      border: none;
  }
}
