.validator-staking-pool {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    background: black;
    border: 1px solid #3C3C3C;
    padding: 24px 16px;
    border-radius: 12px;

    .card-container {
        @media screen and (max-width: 876px) {
            flex-direction: column;
        }
    }
}
.validator-card {
    border-radius: 12px;
    background-color: #0f1112;
    flex: 1;
    padding: 12px 14px;
}
.user-staking {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #0f1112;
    justify-content: space-between;
    padding: 12px 14px;
    border-radius: 12px;
    gap: 10px;
    @media screen and (max-width: 876px) {
        flex-direction: column;
    }
    .label-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        @media screen and (max-width: 876px) {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
        }
        .label {
            font-size: 0.8em;
        }
    }

    .actions {
        display: flex;
        gap: 10px;
        .stake {
            padding-left: 1rem;
            padding-right: 1rem;
            color: white !important;
            border: 1px solid #10ffc6 !important;
            background-color: transparent;

            &:hover {
                background-color: #10ffc6cc !important;
            }
        }

        .claim {
            padding-left: 1rem;
            padding-right: 1rem;
            color: white !important;
            border: 1px solid #00CD5E !important;

            &:hover {
                background-color: #00CD5Ecc !important;
            }
        }

        .unstake {
            padding-left: 1rem;
            padding-right: 1rem;
            color: white !important;
            border: 1px solid #50a5f1 !important;


            &:hover {
                background-color: #50a5f1cc !important;
            }
        }

    }
}

.info-panel {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 12px;
    
    background-color: black;
    .info-row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .label {
            font-size: 13px;
        }
        .value {
            font-size: 13px;
            color: #10ffc6;
        }
    }
}