.balance-panel {
  display: flex;
  flex-direction: column;
  padding: 16px;

  background: black;
  border-radius: 8px;
  
  h4 {
    text-align: left;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  div {
    border-bottom: 1px solid #a6b0cf;
    margin: 8px 0;
  }

  p {
    color: #00ffa3;
    font-size: 15px;
    line-height: 22px;

    margin: 0;
  }
}
