.button {
  background: linear-gradient(180deg, #FFAA12 0%, #915D00 100%);
  border: 1px solid #FFAA12;

  &:hover {
      border: 1px solid #FFAA12;
  }

  &:focus {
      border: 1px solid #FFAA12;
  }
}