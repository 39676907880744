/*
 Theme Name:   Droow Child
 Theme URI:    https://themeforest.net/user/design_grid/portfolio
 Description:  Droow Child Theme
 Author:       Design Grid
 Author URI:   https://themeforest.net/user/design_grid
 Template:     droow
 Version:      1.0.0
 License:      GNU General Public License v2 or later
 License URI:  http://www.gnu.org/licenses/gpl-2.0.html
 Tags:         right-sidebar, accessibility-ready, custom-background, custom-menu, editor-style, featured-images, flexible-header, sticky-post, threaded-comments, translation-ready, blog
 Text Domain:  droow-child
*/

.day-night {
  display: none;
}

/* Custom Timeline */

/*===== Vertical Timeline =====*/
#conference-timeline {
  position: relative;
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}
#conference-timeline .timeline-start,
#conference-timeline .timeline-end {
  display: table;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  background: #70ffdb;
  border-radius: 8px;
  padding: 15px 23px;
  color: #000;
  max-width: 10%;
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
#conference-timeline .conference-center-line {
  position: absolute;
  width: 3px;
  height: 100%;
  top: 0;
  left: 50%;
  margin-left: -2px;
  background: #00b0bd;
  z-index: -1;
}
#conference-timeline .conference-timeline-content {
  padding-top: 67px;
  padding-bottom: 67px;
}
.timeline-article {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin: 20px 0;
}
.timeline-article .content-left-container,
.timeline-article .content-right-container {
  max-width: 44%;
  width: 100%;
}
.timeline-article .timeline-author {
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #70ffdb;
  text-align: right;
}
.timeline-article .content-left,
.timeline-article .content-right {
  position: relative;
  width: auto;
  border: 1px solid #70ffdb;
  border-radius: 8px;
  background-color: #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
  padding: 27px 25px;
}
.timeline-article p {
  margin: 0 0 0 60px;
  padding: 0;
  font-weight: 400;
  color: #a6b0cf;
  font-size: 13px;
  line-height: 16px;
  position: relative;
}
.timeline-article p span.article-number {
  position: absolute;
  font-weight: 300;
  font-size: 44px;
  top: 10px;
  left: -60px;
  color: #70ffdb;
}
.timeline-article .content-left-container {
  float: left;
}
.timeline-article .content-right-container {
  float: right;
}
.timeline-article .content-left:before,
.timeline-article .content-right:before {
  position: absolute;
  top: 20px;
  font-size: 23px;
  font-family: "FontAwesome";
  color: #fff;
}
.timeline-article .meta-date {
  position: absolute;
  top: 0;
  padding-top: 20px;
  left: 50%;
  width: 62px;
  height: 62px;
  margin-left: -31px;
  color: #000;
  border-radius: 100%;
  background: #70ffdb;
}
.timeline-article .meta-date .date,
.timeline-article .meta-date .month {
  display: block;
  text-align: center;
  font-weight: 500;
}
.timeline-article .meta-date .date {
  font-size: 16px;
  line-height: 0px;
}
.timeline-article .meta-date .month {
  font-size: 14px;
  line-height: 0px;
}
.tml {
  font-size: 13px;
  line-height: 23px;
  font-weight: 400;
  padding-bottom: 10px;
}
.stmlnk {
  color: #45a5ff;
  padding-top: 10px;
}
/*===== // Vertical Timeline =====*/

/*===== Resonsive Vertical Timeline =====*/
@media only screen and (max-width: 830px) {
  #conference-timeline .timeline-start,
  #conference-timeline .timeline-end {
    margin: 0;
  }
  #conference-timeline .conference-center-line {
    margin-left: 0;
    left: 50px;
  }
  .timeline-article .meta-date {
    margin-left: 0;
    left: 20px;
  }
  .timeline-article .content-left-container,
  .timeline-article .content-right-container {
    max-width: 100%;
    width: auto;
    float: none;
    margin-left: 110px;
    min-height: 53px;
  }
  .timeline-article .content-left-container {
    margin-bottom: 20px;
  }
  .timeline-article .content-left,
  .timeline-article .content-right {
    padding: 10px 25px;
    min-height: 65px;
  }
  .timeline-article .content-right:before {
    display: none;
  }
}
@media only screen and (max-width: 400px) {
  .timeline-article p {
    margin: 0;
  }
  .timeline-article p span.article-number {
    display: none;
  }
}
/*===== // Resonsive Vertical Timeline =====*/

/*===== // Hologram Steps =====*/

/** Hero Title **/

.page-id-310 .header-hero h1 {
  display: none;
}
.page-id-1909 .header-single-post .inner-box .title-box {
  display: none;
}
.page-id-1164 .dsn-title-header {
  font-size: 45px !important;
}

/** Blinking DOT */

.blinking-green {
  animation: 1s blink-green ease infinite;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
}

// @-webkit-keyframes "blink-green" {
//   from,
//   to {
//     opacity: 0;
//     background-color: #7fffd4;
//   }
//   50% {
//     opacity: 1;
//   }
// }

/** MENU Sub description */

.subd {
  font-size: 9px;
  text-transform: none;
  color: #4bffde;
}
@media (min-width: 448px) {
  .subd {
    font-size: 9px;
    text-transform: none;
    color: #4bffde;
  }
}

.nav__list {
  padding-top: 120px;
}
.nav__list-item {
  font-size: 18px;
  font-weight: 500;
}

.nav__list-item ul li {
  font-size: 14px;
  font-weight: 300;
}

@media only screen and (max-width: 575px) {
  .header-top .header-container {
    padding: 20px 15px;
    background-color: #000000;
  }
}

.site-header .extend-container .main-navigation .custom-drop-down:hover ul {
  opacity: 1;
  visibility: visible;
  top: 40px;
}

/** BLURED BG BOX */

.blur-bg {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(25px);
  border-radius: 20px;
  border-color: #ededed;
  margin: 10px;
}

.page-id-1909 .section-padding {
  padding-top: 20px;
  padding-bottom: 40px;
}

.site-header .extend-container .main-navigation ul li a {
  padding: 15px 0;
  margin-top: 0px;
}

.rdmp {
  text-align: center;
  padding: 25px;
  font-size: 15px;
}

.daobtls-ttl {
  font-size: 23px;
  font-weight: 500;
}
.cbtmfooter {
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0px;
}
.fotter-listp {
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
}

/** Custom footer social icons */

.mainftricons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 1rem;
  padding-left: 1rem;
  gap: 0.8rem;
  margin-bottom: 0.5rem;
  -ms-flex-pack: center;
  justify-content: left;
  align-items: left;
}
.nav-itemctm {
  display: flex;
  flex-direction: column;
  text-transform: none;
  text-decoration: none;
}
.nav-link-textctm {
  font-size: 0.475rem;
  line-height: 1.25rem;
  font-weight: 300;
  text-align: center;
  font-family: inherit;
  color: #fff !important;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

.nav-links {
  color: #000;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  &:hover {
    background-color: #000;
    color: #4bffde !important;
    transform: scaleX(1.1) scaleY(1.1);
  }
}

.comments-post {
  display: none;
}
.contact-up {
  display: none;
}
.plink {
  color: #10fbff;
}

.post-full-content.single-project > *:not(.gallery),
.post-full-content.single-project > *:not(.wp-block-quote) > p {
  margin-bottom: 20px;
}

/** ROADMAP 2024 */

.timeline-article .new24-date {
  position: absolute;
  top: 0;
  padding-top: 23px;
  left: 50%;
  width: 62px;
  height: 62px;
  margin-left: -31px;
  color: #000;
  border-radius: 10px;
  background: #70ffdb;
  font-weight: 900;
}
@media only screen and (max-width: 830px) {
  .timeline-article .new24-date {
    margin-left: 0;
    left: 20px;
  }
}
.timeline-article .left,
.timeline-article .right {
  position: relative;
  width: auto;
  border: 1px solid #70ffdb;
  border-radius: 8px;
  background-color: #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.03);
  padding: 10px 10px;
}

@media only screen and (max-width: 830px) {
  .timeline-article .new24-date {
    left: 10% !important;
  }
}

/** Chess Gallery */
.gallery-col {
  overflow: hidden;
  background-color: #232323;
}
