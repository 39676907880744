.tokenPanel {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: #000;
    border: 1px solid #989898;
    border-radius: 8px;
    padding: .3rem .4rem;
    margin: 10px 0;
    width: 100%;
    
    .tokenSelector {
        cursor: pointer;
        width: 150px;
        // background: #131721;
        // border-radius: 12px;
        
        .tokenName {
            display: flex;
            align-items: center;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            margin-left: 7px;
            
            @media screen and (max-width: 600px){
                font-size: 12px;
            }
        }
        
        .tokenInfo {
            font-size: 10px;
            font-family: 'Poppins', sans-serif;
            line-height: 120%;
            text-align: end;
            color: white;
        }
    }
    
    .tokenInfo {
        span {
            font-size: 14px !important;
        }
    }
}

.input {
    min-width: 100px;
    // max-width: 270px;
}
