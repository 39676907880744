.battle-dao {
  .battle-clans {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 30px;
    padding-bottom: 1rem;
    overflow-x: auto;

    // @media (max-width: 576px) {
    //   flex-direction: column;
    // }

    img {
      width: 100%;
      border: 1px solid #3c3c3c;
      border-radius: 12px;
      max-width: 250px;
    }
  }

  .battle-info {
    padding: 20px 16px;
    // margin-bottom: 30px;

    // background: #232323;
    // border: 1px solid #3C3C3C;
    // border-radius: 12px;

    background: black;
    border-radius: 10px;

    margin-left: 0;
    margin-right: 0;

    h4 {
      // font-style: normal;
      // font-weight: 400;
      // font-size: 20px;
      // line-height: 24px;
      text-align: center;

      color: #00b2ff;
    }

    // h3 {
    //   margin-top: 28px;
    //   font-style: normal;
    //   font-weight: 400;
    //   font-size: 24px;
    //   text-align: center;

    //   color: #00ffa3;
    // }

    h2 {
      background: #000;
      padding: 10px 8px;
      margin: 4px;

      color: #fff;
    }

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: #ffffff;
    }

    span {
      color: #00b2ff;
    }

    hr {
      border-bottom: 1px solid #969696;
    }
  }

  .battle-panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    padding: 20px 16px;
    margin: 0px;

    background: black;
    border: 1px solid transparent;
    border-radius: 10px;
    transition: 0.3s all ease-in-out;

    img {
      width: auto;
      height: 140px;
      margin-left: auto;
      margin-right: auto;
      margin-top: -90px;
      margin-bottom: 20px;
      border: 1px solid transparent;
      border-radius: 10px;

      transition: 0.3s all ease-in-out;
    }

    h3 {
      color: #00b2ff;
    }

    p {
      color: #fff;
      margin-bottom: 0px;
    }

    span {
      margin-top: 25px;
      color: #00ffa3;
    }

    .progress {
      background: #a6b0cf30;
    }

    .progress-bar {
      background: $green !important;
    }

    .support {
      width: 100px;
      background: $green;
      border-radius: 4px;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      border: none;
      margin: auto;
      padding: 4px;

      &:hover {
        background: $green-300;
      }
    }

    &:hover {
      border: 1px solid $green;
      img {
        border: 1px solid $green;
      }
    }
  }

  .battle-rule {
    padding: 16px;
    background: black;
    border-radius: 10px;
    margin: 0px;

    h4 {
      color: #00b2ff;
    }

    p {
      color: #fff;
      margin-bottom: 0px;
    }

    span {
      margin-top: 25px;
      color: #00ffa3;
    }
  }
}

.select2-selection__multi-value {
  background: red !important;
}

.font-code {
  font-family: $font-family-code !important;
}

.pagination-button {
  border-radius: 200px;
  padding: 0.6rem 0.8rem;
}

//
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #232323 #495057;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  // background:lightgray;
  border: 1px solid #3c3c3c;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #495057;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #495057;
  background: #232323;
}
