.swap-inputs-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
}

.learn-ecpx-link {
  color: #00B2FF;
  text-decoration: underline !important;
  font-size: .75rem;

  &:hover {
    color: #00B2FFcc;
  }
}

.swap-token-position-button {
  cursor: pointer;
  // padding: .5rem;
  // border-radius: 12px;
  // background-color: #0f0e0e;
  // border: 1px solid #3C3C3C;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    color: white;
  }
}
