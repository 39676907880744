.nft-staking {
    .nft-staking-pool {
        padding: 24px 16px;
        margin-bottom: 30px;
        background: black;
        border: 1px solid #3C3C3C;
        border-radius: 12px;

        height: 100%;

        display: flex;
        flex-direction: column;

        justify-content: space-between;
        align-items: center;
        text-align: center;

        h3 {
            margin-top: 40px;
            margin-bottom: 20px;
            color: #fff;
        }

        h6 {
            color: #7C7C7C;
        }

        p.pool {
            color: #00FFA3;
        }

        span.item {
            display: block;
            text-align: right;
            margin-bottom: 4px;
        }

        p {
            text-align: left;
            margin-bottom: 4px;
        }

        span {
            color: #fff;
            margin-bottom: 8px;
        }

        button {
            border-radius: 4px;
            padding: 4px 8px;
            margin: 5px auto;
            width: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
        }

        .stake-all {
            background: #10FFC6;
        }

        .unstake-all {
            background: #1B106C;
            color: #fff;
        }

        .claim {
            background: #00CD5E;
        }
    }

    .my-nfts {
        padding: 10px 16px;

        background: #232323;
        border: 1px solid #3C3C3C;
        border-radius: 12px;

        margin-left: 0;
        margin-right: 0;

        margin-bottom: 30px;

        h3 {
            color: white;
        }

        h5 {
            color: #fff;
            margin-top: 20px
        }

        span {
            color: #00FFA3;
            text-decoration: underline;
        }

     
        .nft-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                max-width: 240px;
                border-radius: 8px;
                border: 1px solid #3C3C3C;
            }

            h6.clan {
                color: #fff;
                text-shadow: 0px 0px 5px black;
            }

            h6.identifier {
                font-size: 10px;
                text-shadow: 0px 0px 5px black;
            }

            button {
                bottom: 10px;

                border-radius: 4px;
                padding: 4px 8px;

                display: flex;
                justify-content: center;
                align-items: center;

                background: transparent;

                color: #fff;
                border: 1px solid #fff;

                i {
                    margin-right: 4px;
                }
            }

            button.selected {
                background: #10FFC6;
            }
        }

    }
}

.genesis-select-modal {
    .genesis-modal-body {
        .my-nfts {
            padding: 10px 16px;

            background: #232323;
            border: 1px solid #3C3C3C;
            border-radius: 12px;

            margin-left: 0;
            margin-right: 0;

            margin-bottom: 30px;

            h3 {
                color: white;
            }

            h5 {
                color: #fff;
                margin-top: 20px
            }

            span {
                color: #00FFA3;
                text-decoration: underline;
            }

            .nft-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    max-width: 240px;
                    border-radius: 8px;
                    border: 1px solid #3C3C3C;
                }

                h6.clan {
                    color: #fff;
                    text-shadow: 0px 0px 5px black;
                }

                h6.identifier {
                    font-size: 10px;
                    text-shadow: 0px 0px 5px black;
                }

                button {
                    bottom: 10px;

                    border-radius: 4px;
                    padding: 4px 8px;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    background: transparent;

                    color: #fff;
                    border: 1px solid #fff;

                    i {
                        margin-right: 4px;
                    }
                }

                button.selected {
                    background: #10FFC6;
                }
            }


        }
    }
}

#dapp-modal {
    z-index: 9999 !important;
}

.pools-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: 576px) {
        grid-template-columns: auto;
    }
}