body {
    background: #0F0E0E;
}

.main-content {
    padding: 32px;

    @media (max-width: 576px) {
        padding: 0px;
    }
}

.container {
    max-width: 1280px;
}