.marketplace-body {
    padding: 54px;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 30px;

    background: #232323;
    border: 1px solid #3C3C3C;
    border-radius: 12px;

    color: #fff;
    text-align: center;

    h3 {
        font-size: 30px;
        font-weight: bold;

        max-width: 680px;
        margin: auto;
    }

    h5 {
        font-size: 24px;
        font-weight: medium;

        max-width: 680px;
        margin: auto;

        margin-top: 45px;
    }

    h6 {
        font-size: 20px;
        font-weight: bold;

        max-width: 680px;
        margin: auto;

        margin-top: 75px;
    }

    img {
        margin: 30px auto 0 auto;
        max-width: 300px;
    }

    @media (max-width: 768px) {
        padding: 24px;

        h3 {
            font-size: 24px;
        }
    
        h5 {
            font-size: 18px;
        }
    
        h6 {
            font-size: 12px;
        }
    }
}