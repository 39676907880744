.hub-setting {
    h3, h5 {
        color: #fff;
    }

    .setting-panel {
        background: #232323;
        border: 1px solid #3C3C3C;
        border-radius: 12px;
        padding: 20px 16px;

        margin-bottom: 30px;

        color: white;
    }

    input {
        background: #000;
        border: none;
        border-radius: 8px;
        color: #fff;
        padding-left: 10px;
        height: 30px;
        border: 1px solid #989898;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}