.collection-summary {
  background: black;
  border: 1px solid #10ffc6;
  border-radius: 8px;
  margin-bottom: 30px;
  padding: 20px 16px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  color: white;

  span {
    color: #989898;
  }

  img {
    height: 70px;
    width: 70px;
    margin: 0px 40px 0px 18px;
  }

  a {
    color: #00ffa3;
    text-decoration: underline !important;
  }

  h5 {
    margin: 0;
  }
}

.feature-service {
  padding: 34px 16px;
  margin-left: 0px;
  margin-right: 0px;

  background: #000000;
  border: 1px solid #3c3c3c;
  border-radius: 12px;

  .col-sm-4 > div {
    display: flex;
    flex-direction: column;

    max-width: 200px;
    margin: auto;

    span {
      text-align: center;
      color: #c7c7c7;
    }
  }
}

.buy-token-panel {
  padding: 10px 20px;
  // margin-top: 20px;

  background: #000000;
  // border: 1px solid #3c3c3c;
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    display: flex;
    align-items: center;

    background: #3c3c3c;
    border-radius: 4px;
    padding: 4px 6px;

    color: #10ffc6;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
  }
}

.buy-card {
  padding: 20px 16px;
  border: 1px solid #3c3c3c;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 30px;

  i {
    font-size: 24px;
    margin-bottom: 12px;
    color: #7c7c7c;
  }

  a {
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    text-decoration: underline !important;
  }

  p {
    margin-bottom: 0;
  }
}

.black-card {
  background: black;
  padding: 16px;
  border-radius: 8px;

  a {
    color: #10ffc6;
  }
}

.blue-card {
  background: #000e3f;

  a {
    color: #ffffff;
  }
}

.external-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;

  .icon-box {
    background: white;
    width: 30px;
    height: 30px;
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 14px;
    transition: 0.3s all ease-in-out;
  }

  .link-name {
    font-size: 8px;
    color: gray;
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    .icon-box {
      background: #10ffc6;
    }

    .link-name {
      color: #10ffc6;
    }
  }
}
