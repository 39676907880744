.wallet-connection-content {
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.wallet-connection {
  width: 50%;
  padding-right: 15px;
  border-right: 1px solid #ffffff11;

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-right: 0px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ffffff11;
    border-right: none;
  }
}

.wallet-introduction {
  width: 50%;
  padding-left: 15px;

  .space-between-title-and-description {
    margin-bottom: 80px;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0px;
    padding-top: 20px;

    .space-between-title-and-description {
      margin-bottom: 40px;
    }
  }
}

.leaderboard-card {
  background: black;
  max-height: 516px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  border-radius: 10px;
  padding-top: 16px;

  .table {
    text-align: center;
    font-size: 14px;
  }
}

/* For Webkit-based browsers (Chrome, Safari) */
.leaderboard-card::-webkit-scrollbar {
  width: 4px;
}

.leaderboard-card::-webkit-scrollbar-track {
  background-color: transparent;
}

.leaderboard-card::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.rank-detail-card {
  margin: 5px;

  .logo {
    position: relative;
    margin-left: 30px;
    border-radius: 15px;
    z-index: 1;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: 0.3s all ease-in-out;

    &:hover {
      margin-left: 20px;
    }
  }

  .content {
    height: 450px;
    background: black;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    span {
      position: absolute;
      border-radius: 100vmax;
    }

    .top {
      top: 0;
      left: 0;
      width: 0;
      height: 5px;
      background: linear-gradient(
        90deg,
        transparent 50%,
        rgba(255, 49, 49, 0.5),
        rgb(255, 49, 49)
      );
    }

    .bottom {
      right: 0;
      bottom: 0;
      height: 5px;
      background: linear-gradient(
        90deg,
        rgb(57, 255, 20),
        rgba(57, 255, 20, 0.5),
        transparent 50%
      );
    }

    .right {
      top: 0;
      right: 0;
      width: 5px;
      height: 0;
      background: linear-gradient(
        180deg,
        transparent 30%,
        rgba(0, 255, 255, 0.5),
        rgb(0, 255, 255)
      );
    }

    .left {
      left: 0;
      bottom: 0;
      width: 5px;
      height: 0;
      background: linear-gradient(
        180deg,
        rgb(255, 255, 113),
        rgba(255, 255, 113, 0.5),
        transparent 70%
      );
    }

    .top {
      animation: animateTop 5s ease-in-out infinite;
    }

    .bottom {
      animation: animateBottom 5s ease-in-out infinite;
    }

    .right {
      animation: animateRight 5s ease-in-out infinite;
    }

    .left {
      animation: animateLeft 5s ease-in-out infinite;
    }
  }
}

@keyframes animateTop {
  25% {
    width: 100%;
    opacity: 1;
  }

  30%,
  100% {
    opacity: 0;
  }
}

@keyframes animateBottom {
  0%,
  50% {
    opacity: 0;
    width: 0;
  }

  75% {
    opacity: 1;
    width: 100%;
  }

  76%,
  100% {
    opacity: 0;
  }
}

@keyframes animateRight {
  0%,
  25% {
    opacity: 0;
    height: 0;
  }

  50% {
    opacity: 1;
    height: 100%;
  }

  55%,
  100% {
    height: 100%;
    opacity: 0;
  }
}

@keyframes animateLeft {
  0%,
  75% {
    opacity: 0;
    bottom: 0;
    height: 0;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

.top-clans-card {
  background: rgb(0, 0, 0);
  color: #726b6b;
  border-radius: 10px;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: start;
    }
  }
}

.background-video {
  width: 100%;
  height: 250px;
  background-position: center center;
  background-size: contain;
  object-fit: cover;
  border-radius: 10px;
}

.counter-number {
  font-size: 32px;
  font-weight: $font-weight-semibold;
  text-align: center;
  display: flex;
  span {
    font-size: 16px;
    font-weight: $font-weight-normal;
    display: block;
    padding-top: 5px;
  }
}

.coming-box {
  width: 25%;
}

.dropdown-menu {
  background: #232323;
  color: white;

  .dropdown-item {
    color: #989898;

    &:hover {
      background: #0000003f;
    }
  }
}

[data-layout-mode="dark"] .card {
  --bs-card-bg: #000000;
  border-radius: 10px;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #33ffd0bf !important;
}

.btn-soft-success {
  border: 1px solid #33ffd0bf;
}

.ecpx-table {
  text-align: center;

  thead {
    tr {
      th {
        background: transparent;
      }
    }
  }

  tbody {
    tr {
      vertical-align: middle;
      transition: 0.3s all ease-in-out;

      td {
        background: transparent;
      }

      &:hover {
        background: #65d8ae52;
      }
    }
  }
}

.battle-id-box {
  background: rgba(0, 0, 0, 0.75);
  padding: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 64px;
  font-weight: 700;
  border-radius: 10px 10px 0 0;
}

.search-filter {
  width: 100%;
  justify-content: center;
  gap: 12px;
  margin: 12px 0px;
  .form-control {
    max-width: 300px;
  }
  .search-btn {
    padding-left: 1rem;
    padding-right: 1rem;
    color: white !important;
    border: 1px solid #10ffc6 !important;
    background-color: transparent;
    border-radius: 8px;
    transition: background-color 0.5s ease;
    &:hover {
        background-color: #10ffc6cc !important;
    }
  }

  .export-btn {
    display: flex;
    align-items: center;
    padding-left: 1rem;
    padding-right: 1rem;
    color: white !important;
    border: 1px solid rgb(85, 110, 230) !important;
    background-color: transparent;
    border-radius: 8px;
    transition: background-color 0.5s ease;

    &:hover {
        background-color: rgb(85, 110, 230) !important;
    }
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    .form-control {
       width: 100%;
      max-width: 100%;
    }
  }
}

.recent-activity {
  padding: 16px;
  // background: #232323;
  // border: 1px solid #3C3C3C;
  // border-radius: 12px;
  background: black;
  border-radius: 10px;
  color: #726b6b;

  // h5 {
  //     color: #FFFFFF;
  // }

  // thead tr th {
  //     color: #7C7C7C;
  // }

  // tbody tr td {
  //     color: #FFFFFF;
  // }

  // tr {
  //     border: #3C3C3C;
  // }

  h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #00b2ff;
  }
}

.chart-tooltip {
  color: black;
}


.dapp-core-component__transactionsToastList-styles__toasts.dapp-core-component__transactionsToastList-styles__toastWrapper {
  border: 1px solid $green !important;
  background: black !important;
}

.dapp-core-component__dappModalStyles__dappModalBody{
  // border: 1px solid $green !important;
  background: black !important;
}

.dapp-core-component__transactionToast-styles__close svg {
  color:#fff
}
