.my-tokens-panel {
  padding: 20px 16px;
  background: black;
  border-radius: 10px;

  max-height: 420px;
  overflow-y: auto;
  scrollbar-width: 10px;

  h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    color: #c7c7c7;
  }

  .app-search {
    padding: 0;
    margin-bottom: 12px;

    input {
      background: #0f0e0e;
      border-radius: 8px;

      color: #fff;
    }
  }

  .token-balance {
    margin-top: 10px;
    padding: 12px 0;
    border-bottom: 1px solid #3c3c3c;

    h6 {
      color: #fff;
      font-size: 14px;
    }

    span {
      font-size: 12px;
    }

    p {
      color: #989898;
      font-size: 8px;
    }

    h6,
    p {
      margin: 0;
    }

    .divider {
      height: 50px;
      border-right: 1px solid #7c7c7c;
    }
  }
}
