.card {
  background: black;
  border: 1px solid #3C3C3C;
  // padding: 1px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  padding: 24px 24px 32px;
  gap: 3px;
  box-sizing: border-box;

  @media screen and (max-width: 685px) {
    padding: 0.4rem 0.2rem;
  }

  .cardBody {
    background: transparent;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media screen and (max-width: 685px) {
      padding: 12px;
    }

    span {
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      color: white;
    }

    .upDownContainer {
      width: 100%;
      height: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;

      .upDown {
        cursor: pointer;
        width: 48px;
        height: 48px;
        border-radius: 12px;
        background-color: #0f0e0e;
        border: 1px solid #3c3c3c;

        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: white !important;
          transform: rotate(90deg);
        }

        // &:hover {
        //     background-color: #1a1919;
        // }
      }
    }
  }
}

.swapInfo {
  width: 100%;
  border: none;
  background: transparent;
  padding: 1px;
  border-radius: 15px;

  .swapInfoBody {
    background: transparent;
    border-radius: 15px;
    padding: 10px 20px;

    .slippageBtn {
      background: #06071a;
      margin-left: 10px;

      width: 60px !important;
      height: 24px;

      padding: 0 10px;

      border: none !important;
      border-radius: 8px;
      color: white;
      font-size: 12px;

      &:active {
        background: linear-gradient(180deg, #ffaa12 0%, #915d00 100%);
      }

      &:hover,
      &:focus {
        background: #ff9d19;
        color: white;
      }
    }

    .active {
      background: #ff9d19;
      color: white;
    }

    .slippageInput {
      font-size: 0.8rem !important;
      text-align: center;

      margin-left: 10px;

      width: 66px !important;
      height: 24px;

      padding: 0 10px;

      background: #06071a;
      border: none !important;
      border-radius: 8px;
      color: white;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.applyButton {
  margin-top: 30px;
  width: 100%;
  height: 50px;

  background: #ff9d19 !important;
  border-radius: 12px;

  color: #06071a !important;

  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  font-family: "Inter", sans-serif;
}

.disclaimer {
  background: rgba(6, 10, 26, 0.05);
  backdrop-filter: blur(9px);

  i {
    display: none !important;
  }

  .disclaimerInfo {
    margin-top: 15px;
    max-height: 420px;
    overflow-y: scroll;
    background: #060a1a;
    border: 1px solid #06071a;
    backdrop-filter: blur(27px);
    border-radius: 30px;
    padding: 24px 24px 32px;
    align-items: flex-start;

    color: white;
  }
}

.stakeButton {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #000 !important;
  background-color: #10ffc6 !important;

  &:hover {
    background-color: #10ffc6cc !important;
  }
}

.closeButton {
  padding-left: 1rem;
  padding-right: 1rem;
  color: #000 !important;
  background-color: #a6b0cf !important;

  &:hover {
    background-color: #a6b0cfcc !important;
  }
}
