.buy-package {
    .nft-package {
        padding: 42px 0;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        background: #232323;
        border: 1px solid #3C3C3C;
        border-radius: 12px;
        
        min-height: 400px;
        margin-bottom: 30px;

        h3 {
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;

            color: #FFFFFF;
            margin-top: 10px;
        }

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;

            margin: 0;

            color: #7C7C7C;
        }

        a {
            color: #00FFA3 !important;
            margin-top: 60px;
            font-weight: 275;
            font-size: 13px;
            line-height: 20px;

            text-decoration: underline !important;
        }

        button {
            background: #00CD5E;
            border-radius: 4px;
            border: none;
            padding: 9px 27px;

            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
        }

        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #00FFA3;
        }
    }

    .package-info {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 40px;

        background: #232323;
        border: 1px solid #3C3C3C;
        border-radius: 12px;

        padding: 20px 16px;

        p {
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;

            color: #E9E9E9;
        }

        span {
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;

            color: #C7C7C7;
        }
    }

    .ecpx-info {
        padding: 16px 24px;
        margin-bottom: 30px;

        background: #000000;
        border: 1px solid #545454;
        border-radius: 12px;

        display: flex;
        align-items: center;

        img {
            height: 90px !important;
        }

        div {
            h5 {
                font-weight: 700;
                font-size: 15px;
                line-height: 22px;

                color: #C7C7C7;

                span {
                    color: #00B2FF;
                }

                a {
                    color: #10FFC6 !important;
                    text-decoration: underline !important;
                }
            }

            p {
                margin: 0;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;

                color: #C7C7C7;

                a {
                    text-decoration: underline !important;
                    color: #00B2FF !important;
                }
            }
        }
    }
}